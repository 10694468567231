import React, {useRef , useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LOCATIONS from "../../router/locations";
import { Link, useLocation } from "react-router-dom";
import { useQueryParams } from "../../hooks/localStorage/localStorage";
import styled from "styled-components";
import Logo from "../Logo/logo";
import HeaderSearch from "../templates/headerSearch/headerSearch";
import UserMenu from "./userDropDown";
import { formatLocations } from "../../utils/utils";

function Header() {
  const [params] = useQueryParams()
  const nonHeaderPages = formatLocations([LOCATIONS.exampage, LOCATIONS.login, LOCATIONS.register, LOCATIONS.profile ,LOCATIONS.accountdelete ,LOCATIONS.privacy ,LOCATIONS.appstore], params)
  const currentPath = useLocation();
  const ref = useRef(null);
  const [isOpen,setIsOpen] = useState();


  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
       document.addEventListener("mousedown", handleClickOutside);
    return () => {
       document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  if (nonHeaderPages.includes(currentPath.pathname)) return <></>;

  return (
    <HeaderStyled>
      <Navbar ref={ref} expand="lg" className="bg-body-tertiary" expanded={isOpen} onToggle={()=>{
            setIsOpen(!isOpen);
          }}>
        <Container >
          <Navbar.Brand href="#" >
           <Link to="/">
            <Logo type="small" />
           </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" >
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <HeaderSearch />
            <div className="end-action-container" >
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M25.3333 22.6667V15.7333C24.6667 15.8667 24 16 23.3333 16H22.6667V24H9.33333V14.6667C9.33333 10.9333 12.2667 7.99999 16 7.99999C16.1333 6.26666 16.9333 4.79999 18 3.59999C17.6 3.06666 16.8 2.66666 16 2.66666C14.5333 2.66666 13.3333 3.86666 13.3333 5.33332V5.73332C9.33333 6.93332 6.66667 10.5333 6.66667 14.6667V22.6667L4 25.3333V26.6667H28V25.3333L25.3333 22.6667ZM13.3333 28C13.3333 29.4667 14.5333 30.6667 16 30.6667C17.4667 30.6667 18.6667 29.4667 18.6667 28H13.3333Z" fill="#B8B8D2" />
                <circle cx="23.3333" cy="8.66667" r="4.66667" fill="#5ED24B" />
              </svg> */}
              <UserMenu />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </HeaderStyled>
  );
}

export default Header;


const HeaderStyled = styled.div`
  margin-bottom: 75px;
  
  .navbar{
    filter: drop-shadow(0px 2px 15px rgba(184, 184, 210, 0.50));
    z-index: 999;
    position: fixed;
    width: 100%;
    top: 0;

  }

  .end-action-container{ 
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  @media only screen and (max-width: 992px) {
      .end-action-container{
        margin-top: 20px;
        margin-left: 0px;
        justify-content: space-between;
      }

      .navbar{
          margin-left: 0px;
          justify-content: space-between;
      }
  }

  @media only screen and (max-width: 1440px) {
    .navbar{
      // max-width:1440px;
    }
  }
`
