import { styled } from "styled-components"
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from "react";
import useGetQuery from "../../../hooks/react-query/useGetQuery";
import { course_search } from "../../../core/apiEndpoint";
import { Spinner } from "react-bootstrap";
import EmptySpace from "../../EmptySpace/emptySpace";
import { Link } from "react-router-dom";
import LOCATIONS from "../../../router/locations";

const HeaderSearch = () => {
    const [search, setSearch] = useState(null)
    const { responseData, isLoading, isError } = useGetQuery({ endPoint: `${course_search}?query=${search}` })

    const getMenuItems = () => {
        if (isLoading) return <div className="spinner-container" ><Spinner /></div>;
        if(isError || !Boolean(responseData?.data?.length))return <div className="spinner-container" ><EmptySpace message="No Data Found" /></div>;
        return (
            <>
                {responseData?.data?.map?.((course) => {
                    return (
                      
                        <Dropdown.Item eventKey="1">
                             <Link onClick={()=> setSearch('')} to={`${LOCATIONS.search.replace(":id", course?.id)}?name=${course?.course_name}`} >
                           <div className="d-flex" >
                            <img className="drop-down-image" src={course?.course_image}/>
                           <div className="px-2" >
                                <div className="course-name" >{course?.course_name}</div>
                                <div className="course-category" >Category name</div>
                            </div>
                           </div>
                           <div className="divider-search" />
                           </Link>
                        </Dropdown.Item>
                    
                    )
                })}
            </>
        )
    }

    return (
        <HeaderSearchStyled>
            <Dropdown show={Boolean(search)} >
                <div className="">
                    <div className=" height ">
                        <div className="">
                            <div className="search-input-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.666626 6.99999C0.666626 3.5022 3.50217 0.666656 6.99996 0.666656C10.4977 0.666656 13.3333 3.5022 13.3333 6.99999C13.3333 8.50875 12.8057 9.89429 11.925 10.9822L14.3737 13.431C14.6341 13.6913 14.6341 14.1134 14.3737 14.3738C14.1134 14.6341 13.6913 14.6341 13.4309 14.3738L10.9822 11.925C9.89426 12.8057 8.50872 13.3333 6.99996 13.3333C3.50217 13.3333 0.666626 10.4978 0.666626 6.99999ZM6.99996 1.99999C4.23855 1.99999 1.99996 4.23858 1.99996 6.99999C1.99996 9.7614 4.23855 12 6.99996 12C9.76137 12 12 9.7614 12 6.99999C12 4.23858 9.76137 1.99999 6.99996 1.99999Z" fill="#B8B8D2" />
                                </svg>

                                <input
                                    type="text"
                                    className="search-input"
                                    placeholder="Search "
                                    onChange={(e) => setSearch(e?.target?.value || null)}
                                    value={search}

                                />
                                <Dropdown.Menu className="menu-container">
                                    {getMenuItems()}
                                </Dropdown.Menu>

                            </div>
                        </div>
                    </div>
                </div>
            </Dropdown>
        </HeaderSearchStyled>
    )
}

export default HeaderSearch;

const HeaderSearchStyled = styled.div`
    border-radius: 12px;
    border: 1px solid #B8B8D2;
    background: #FFF;
    .search-input-container{
        display: flex;
        align-items: center;
        padding-left: 16px;
        margin-right: 10px;

    }

    .search-input{   
         border: none;
         width: 343px;
    }

    .spinner-container{
        width: 100%;
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu-container{
        width: 100%;
        box-shadow: 0px 2px 15px 0px rgba(184, 184, 210, 0.50);
        border-radius: 12px;
        border: unset;
        width: 487px;

    }

     @media  only screen and (max-width: 628px) {
        .menu-container{
          width:100%;
        }

    }

    .drop-down-image{
        width: 35px;
        height: 35px;
        border-radius: 4px;
    }

    .course-name{
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; 
        text-wrap:wrap;
        text-align:left;
    }

    .course-category{
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.5px;
    }

    .divider-search{
        border-bottom: 0.5px solid #B8B8D2;
        padding-top:  10px; 
    }


    @media  only screen and (max-width: 400px) {
        .search-input{
          width:100%;
        }

    }
`